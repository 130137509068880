import React, { useMemo } from 'react';
import { Form, Input, Button, Card, Popconfirm, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAnonymous } from '../context/anonymousContext';

const ArrayWidget = ({ schema, value = [], onChange, formContext }) => {
  const [form] = Form.useForm();
  // console.log(schema, "hooo")
  const arrayName = schema.arrayName

  const properties = schema?.items?.properties || {};
  // console.log(properties, "array");
  const PRIMARY_COLOR = '#2e328a';
  const { serverErrors } = formContext || {}
  // console.log(serverErrors, "helo");
  const { status } = useAnonymous();
  const isDisabled = status === 'submitted';

  // Memoized error processing with robust error handling
  const processedErrors = useMemo(() => {
    // Normalize errors to an array
    const errors = Array.isArray(formContext?.errors)
      ? formContext.errors
      : (formContext?.errors ? [formContext.errors] : []);

    // Create a map to easily look up errors
    const errorMap = new Map();

    // Safe forEach with type checking
    if (Array.isArray(errors)) {
      errors.forEach(error => {
        // Ensure error is an object and has a property field
        if (error && typeof error === 'object' && error.property) {
          // Remove leading dot if present
          const cleanProperty = error.property.replace(/^\./, '');

          // Special handling for nested properties like businessDetails.0.businessName
          if (cleanProperty.includes('.')) {
            // Split the property path
            const parts = cleanProperty.split('.');

            // For nested properties, we'll store with different variations
            errorMap.set(cleanProperty, error.message);

            // Also store with index-based property
            if (parts.length > 2) {
              const indexedProperty = `${parts[0]}[${parts[1]}].${parts[2]}`;
              errorMap.set(indexedProperty, error.message);
            }
          } else {
            // For direct properties
            errorMap.set(cleanProperty, error.message);
          }
        }
      });
    }

    return errorMap;
  }, [formContext?.errors]);

  // Function to organize properties into rows of maximum 3
  const getPropertyRows = () => {
    const propertyEntries = Object.entries(properties);
    const rows = [];
    for (let i = 0; i < propertyEntries.length; i += 3) {
      rows.push(propertyEntries.slice(i, i + 3));
    }
    return rows;
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (onChange) {
      const fieldName = schema.title.replace(/\s+/g, '');
      onChange(allValues[fieldName] || []);
    }
  };

  const fieldName = schema.title.replace(/\s+/g, '');
  const propertyRows = getPropertyRows();

  return (
    <Form
      form={form}
      initialValues={{ [fieldName]: value }}
      onValuesChange={handleValuesChange}
      disabled={isDisabled}
    >
      {/* <div className="mb-2 font-medium">{schema.title}</div> */}

      <Form.List name={fieldName}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Card
                key={key}
                style={{ marginBottom: 16 }}
                title={`Entry ${index + 1}`}
                extra={
                  !isDisabled && fields.length > 1 && (
                    <Popconfirm
                      title={`Are you sure you want to delete this ${schema.title} entry?`}
                      onConfirm={() => remove(name)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <MinusCircleOutlined
                        style={{ color: '#ff4d4f', cursor: 'pointer' }}
                      />
                    </Popconfirm>
                  )
                }
              >
                <div style={{ marginBottom: -24 }}>
                  {propertyRows.map((row, rowIndex) => (
                    <div
                      key={rowIndex}
                      style={{
                        display: 'flex',
                        marginBottom: 24,
                        gap: 16
                      }}
                    >
                      {row.map(([propertyKey, propertySchema]) => {
                        // Construct error lookup keys
                        const directPropertyKey = propertyKey;
                        const nestedPropertyKey = `${fieldName}[${index}].${propertyKey}`;
                        const businessDetailsKey = `${arrayName}[${index}].${propertyKey}`;

                        // Check for errors using multiple lookup strategies
                        const fieldError =
                          processedErrors.get(directPropertyKey) ||
                          processedErrors.get(nestedPropertyKey) ||
                          processedErrors.get(businessDetailsKey);

                        const isEnum = Array.isArray(propertySchema.enum); // Check if enum exists for the field

                        return (
                          <div
                            key={propertyKey}
                            style={{
                              flex: `0 0 calc(${100 / row.length}% - ${(row.length - 1) * 8}px)`
                            }}
                          >
                            <div

                            >
                              {propertySchema.title}
                            </div>
                            <Form.Item
                              {...restField}
                              name={[name, propertyKey]}
                              help={fieldError}
                              style={{ marginBottom: 0 }}
                            >
                              {isEnum ? (
                                // If 'enum' exists, render as a dropdown (Select)
                                <Select
                                  placeholder={propertySchema.title}
                                  disabled={isDisabled}
                                >
                                  {propertySchema.enum.map(option => (
                                    <Select.Option key={option} value={option}>
                                      {option}
                                    </Select.Option>
                                  ))}
                                </Select>
                              ) : (
                                // Otherwise, render as a text input field
                                <Input
                                  placeholder={propertySchema.title}
                                  disabled={isDisabled}
                                />
                              )}
                            </Form.Item>
                            {/* <div className="div">
                              // {console.log(serverErrors?.businessDetails?.[rowIndex]?.[`${propertyKey}`], "eho")}
                            </div> */}

                            <span style={{ color: 'red' }}>
                              {serverErrors?.arrayName?.[rowIndex]?.[`${propertyKey}`]}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ))}

                </div>
              </Card>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add({ [fieldName]: value })}
                block
                icon={<PlusOutlined style={{ color: PRIMARY_COLOR }} />}
                style={{
                  marginTop: 16,
                  borderColor: PRIMARY_COLOR,
                  color: PRIMARY_COLOR
                }}
                className="hover:border-[#2e328a] hover:text-[#2e328a]"
                disabled={isDisabled}
              >
                Add {schema.title}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default ArrayWidget;