import { message } from 'antd';

export function transformErrors(errors) {
  console.log(errors,"err")
  const requiredFieldErrors = errors.filter((error) => error.name === 'required');
  const missingFields = [];

  if (requiredFieldErrors.length > 0) {
    errors.forEach((error) => {
      if (error.name === 'required') {
        // Extract the last part of the property path (the field name)
        const fieldName = error.property.split('.').pop();
        missingFields.push(fieldName);
        //error.message = `${fieldName} is required`;

        error.message = `Required`;
      }
    });

    // Get the total number of missing fields
    const totalMissingFields = missingFields.length;

    // Get the first field that has an error
    const firstMissingField = missingFields[0];

    // Show a toast message with the total number and the first missing field
    message.error(`Total fields missing: ${totalMissingFields}. First missing field: ${firstMissingField}.`);

    // Try to find the first field and focus on it
    let fieldElement = document.querySelector(`[name$="${firstMissingField}"]`); // Match the field name
    if (!fieldElement) {
      // Try the root prefix, as @rjsf sometimes adds a prefix like root_
      fieldElement = document.querySelector(`[id$="_root_${firstMissingField}"]`);
    }
    if (!fieldElement) {
      // Try a direct ID match
      fieldElement = document.querySelector(`#root_${firstMissingField}`);
    }

    // Focus on the field if found
    if (fieldElement && typeof fieldElement.focus === 'function') {
      fieldElement.focus(); // Focus on the field
    } else {
      console.error('Unable to focus on the field:', firstMissingField);
    }
  }

  return errors;
}
