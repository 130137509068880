import React from "react";
import { Menu, Button, Row, Col, Dropdown } from "antd";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";

const Navbar = () => {
  const topMenuItems = [
    "MBL M-Card",
    "Machhapuchchhre Capital",
    "Smart Chautari",
    "Interest Rates",
    "Career",
    "Remitap Nepal",
    "Online FD",
    "New account number",
    "Dispute Claim",
  ];

  const bottomMenuItems = [
    "About Us",
    "Savings Deposits",
    "Borrowings",
    "Digital Services",
    "NRN",
    "Information",
  ];

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1">Option 1</Menu.Item>
      <Menu.Item key="2">Option 2</Menu.Item>
    </Menu>
  );

  return (
    <div>
      {/* Top Navigation */}
      <div className="top-nav-section justify-content-between container-fluid position-relative d-flex">
        <div className="top-left-wrapper d-flex align-items-center justify-content-between">
          <ul className="top-nav-left hide-mobile p-0 d-flex m-0 list-unstyled">
            {topMenuItems.map((item, index) => (
              <li>
                <a href="" target="_blank" rel="nofollow">
                  {item}
                </a>
              </li>
            ))}
          </ul>
          <span className="language-toggle">
            <a
              href="javascript:void(0);"
              className="toggle-lang d-inline-block text-center active"
              data-lang="EN"
            >
              EN
            </a>
            <a
              href="javascript:void(0);"
              className="toggle-lang d-inline-block text-center "
              data-lang="NP"
            >
              नेपा
            </a>
          </span>
        </div>
        <div className="top-right-wrapper">
          <form className="position-relative">
            <button type="submit">
              <SearchOutlined />
            </button>
            <input type="text" placeholder="Search" />
          </form>
        </div>
      </div>

      {/* Bottom Navigation */}

      <div className="headerfull">
        {/*Main Menu HTML Code*/}
        <div className="bg-red">
          <div className="nav-bar">
            <div className="align-items-center d-flex justify-content-between ">
              <div className="home-img">
                <a href="https://www.machbank.com/">
                  <img
                    className="w-100"
                    src="https://www.machbank.com/assets/backend/uploads/Logo/new logo.JPG"
                    alt="Machhapuchchhre Bank Limited"
                    title="Machhapuchchhre Bank Limited"
                  />
                </a>
              </div>
              <div className="d-flex pt-2 pb-2">
                <ul className="mobile-sub  p-0 m-0 list-unstyled d-flex px-3">
                  {bottomMenuItems.map((item, index) => (
                    <li className="first-main-nav">
                      <a
                        href="javascript:void(0)"
                        className="navtext position-relative px-2"
                      >
                        {/* About Us <span>Our Company</span> */}
                        {item}
                      </a>
                    </li>
                  ))}
                </ul>

                {/* <div className="align-items-center button-wrap d-flex me-3 gap-3">
                  <Button type="primary" >Log In</Button>
                  <Button type="primary" >Apply Now</Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
