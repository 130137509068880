// import React from 'react';
// import { Row, Col, Typography } from 'antd';

// const { Title } = Typography;

// const ObjectFieldTemplate = (props) => {
//   const { properties, title } = props;

//   const gridLayout = {
//     basicInformation: [
//       'salutation',
//       'accountName',
//       'dateOfBirthBS',
//       'dateOfBirthAD',
//       'gender',
//       'nationality',
//       'education',
//       'branch'
//     ],
//     permanentAddress: [
//       'permanentCountry',
//       'permanentProvince',
//       'permanentDistrict',
//       'permanentLocalBody',
//       'permanentHouseNo',
//       'permanentWardNo',
//       'permanentToleStreet',
//       'permanentPhoneNo',
//       'permanentSearchAddress'
//     ],
//     temporaryAddress: [
//       'sameAsPermanent',
//       'temporaryCountry',
//       'temporaryProvince',
//       'temporaryDistrict',
//       'temporaryLocalBody',
//       'temporaryHouseNo',
//       'temporaryWardNo',
//       'temporaryToleStreet',
//       'temporaryPhoneNo',
//       'temporarySearchAddress'
//     ],
//     communicationDetails: [
//       'phoneNo',
//       'mobileNumber',
//       'email'
//     ],
//     identificationDetails: [
//       'documentType',
//       'identityNo',
//       'issuedDistrict',
//       'issuedDateBS',
//       'issuedDateAD',
//       'panNo',
//       'panNoIssuedDateBS',
//       'panNoIssuedDateAD'
//     ]
//   };

//   const renderSection = (sectionTitle, fields) => (
//     <div key={sectionTitle} className="mb-8">
//       <Title level={5} className="mb-4">{sectionTitle}</Title>
//       <Row gutter={[16, 16]}>
//         {fields.map(fieldName => {
//           const field = properties.find(p => p.name === fieldName);
//           return field ? (
//             <Col key={fieldName} xs={24} sm={12} md={8} lg={6}>
//               {field.content}
//             </Col>
//           ) : null;
//         })}
//       </Row>
//     </div>
//   );

//   return (
//     <div>
//       <Title level={3} className="mb-6">{title}</Title>
//       {renderSection('Basic Information', gridLayout.basicInformation)}
//       {renderSection('Permanent Address', gridLayout.permanentAddress)}
//       {renderSection('Temporary Address', gridLayout.temporaryAddress)}
//       {renderSection('Communication Details', gridLayout.communicationDetails)}
//       {renderSection('Identification Details', gridLayout.identificationDetails)}
//     </div>
//   );
// };

// export default ObjectFieldTemplate;

// import React from 'react';
// import { Row, Col, Typography } from 'antd';
// import { useParams } from 'react-router-dom';

// const { Title } = Typography;

// const ObjectFieldTemplate = (props) => {
//   const { properties, title, schema } = props;
//   const { id: paramsId, stepslug, slug } = useParams();
//   console.log(stepslug,"test");
//   // Group properties by their group
//   const groupedFields = properties.reduce((acc, field) => {
//     const group = schema.properties[field.name]?.group || 'other';
//     if (!acc[group]) {
//       acc[group] = [];
//     }
//     acc[group].push(field);
//     return acc;
//   }, {});

//   // Sort groups by their order
//   const sortedGroups = Object.entries(groupedFields).sort((a, b) => {
//     const orderA = schema.groups?.[a[0]]?.order || 999;
//     const orderB = schema.groups?.[b[0]]?.order || 999;
//     return orderA - orderB;
//   });

//   const getColSpan = (totalFields) => {
//     if (totalFields === 2) {
//       return { xs: 24, sm: 12, md: 12, lg: 12 };
//     }
//     return { xs: 24, sm: 12, md: 8, lg: 8 };
//   };

//   const renderArrayField = (field) => (
//     <Col span={24}>
//       {/* Title of the array field */}
//       <div className="mb-4">
//         <Title level={5}>{field.content.props.title || field.name}</Title>
//       </div>
//       {/* Just render the array field content directly */}
//       {field.content}
//     </Col>
//   );

//   const renderNormalField = (field, totalFields) => (
//     <Col {...getColSpan(totalFields)} key={field.name}>
//       {/* Use the field's content directly without adding a new label */}
//       {field.content}
//     </Col>
//   );

//   const renderMapField = (field) => (
//     <Col span={24} key={field.name}>
//       <div className="mt-4">{field.content}</div>
//     </Col>
//   );

//   const renderSection = (groupKey, fields) => {
//     const groupTitle = schema.groups?.[groupKey]?.title || groupKey;

//     // Filter normal fields, array fields, and map fields
//     const normalFields = fields.filter(
//       (field) => !schema.properties[field.name]?.isMapField &&
//                  schema.properties[field.name]?.type !== 'array'
//     );
//     const arrayFields = fields.filter(
//       (field) => schema.properties[field.name]?.type === 'array'
//     );
//     const mapFields = fields.filter(
//       (field) => schema.properties[field.name]?.isMapField
//     );

//     return (
//       <div key={groupKey} className="mb-8">
//         <Title level={5} className="mb-4" style={{ color: '#2e328a',fontSize:'16px',fontWeight:700,fontFamily:'Avenir LT Std 65 Medium' }}>{groupTitle}</Title>

//         {/* Render normal fields in a grid */}
//         {normalFields.length > 0 && (
//           <Row gutter={[16, 16]} className="mb-4">
//             {normalFields.map((field) => renderNormalField(field, normalFields.length))}
//           </Row>
//         )}

//         {/* Render array fields one by one */}
//         {arrayFields.length > 0 && (
//           <Row gutter={[16, 16]}>
//             {arrayFields.map((field) => renderArrayField(field))}
//           </Row>
//         )}

//         {/* Render map fields at the end with full width */}
//         {mapFields.length > 0 && (
//           <Row gutter={[16, 16]}>
//             {mapFields.map((field) => renderMapField(field))}
//           </Row>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div>
//       <Title level={3} className="mb-6" > {title}</Title>
//       {sortedGroups.map(([groupKey, fields]) => renderSection(groupKey, fields))}
//     </div>
//   );
// };

// export default ObjectFieldTemplate;

import React from "react";
import { Row, Col, Typography, Divider } from "antd";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const ObjectFieldTemplate = (props) => {
  const { properties, title, schema } = props;
  const isMinor = props.formData.salutation === "MINOR";

  const { id: paramsId, stepslug, slug } = useParams();

  // Dynamically check if a field should be filtered based on its condition
  // const shouldFilterField = (fieldName) => {
  //   const fieldSchema = schema.properties[fieldName];

  //   // Check if the field has a condition
  //   if (fieldSchema?.condition) {
  //     const { dependOn, value } = fieldSchema.condition;

  //     // Check if the condition matches the current form data
  //     return props.formData[dependOn] === value;
  //   }

  //   // If no condition, don't filter the field
  //   return false;
  // };
  const isFieldHidden = (fieldName) => {
    const fieldSchema = schema.properties[fieldName];
    return fieldSchema?.['ui:hidden'] === true;
  };

  // Group properties by their group, applying dynamic filtering
  const groupedFields = properties
    .filter(field => !isFieldHidden(field.name))
    .reduce((acc, field) => {
      const group = schema.properties[field.name]?.group || "other";

      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(field);
      return acc;
    }, {});

  // Sort groups by their order
  const sortedGroups = Object.entries(groupedFields).sort((a, b) => {
    const orderA = schema.groups?.[a[0]]?.order || 999;
    const orderB = schema.groups?.[b[0]]?.order || 999;
    return orderA - orderB;
  });

  const getColSpan = (groupKey, totalFields, field) => {
    // If the group is 'documents' or the field is a file field, use full width
    if (
      groupKey === "documents" ||
      schema.properties[field.name]?.isFileField ||
      field?.content?.props?.schema?.width
    ) {
      return { span: 24 };
    }

    // Original column span logic for other groups
    if (totalFields === 2) {
      return { xs: 24, sm: 12, md: 12, lg: 12 };
    }
    return { xs: 24, sm: 12, md: 8, lg: 8 };
  };

  const renderArrayField = (field) => (
    console.log(field, "ff"),
    <Col span={24}>
      {React.cloneElement(field.content, {
        // Modify props to remove title and label
        schema: {
          ...field.content.props.schema,
          // title: null, // Remove title
        },
        uiSchema: {
          ...field.content.props.uiSchema,
          'ui:label': false, // Remove label
          // 'ui:title': null, // Remove title
        },
      })}
    </Col>
  );

  const renderNormalField = (field, groupKey, totalFields) => {
    // For documents group or file fields, create a custom wrapper to remove label
    if (
      groupKey === "documents" ||
      schema.properties[field.name]?.isFileField
    ) {
      return (
        <Col {...getColSpan(groupKey, totalFields, field)} key={field.name}>
          <div className="no-label-field">
            {React.cloneElement(field.content, {
              // Modify props to remove label
              schema: {
                ...field.content.props.schema,
                title: null, // Remove title
              },
              uiSchema: {
                ...field.content.props.uiSchema,
                "ui:label": false, // Explicitly remove label
                "ui:title": null, // Remove UI title
              },
            })}
          </div>
        </Col>
      );
    }

    // Default rendering for other groups
    return (
      <Col {...getColSpan(groupKey, totalFields, field)} key={field.name}>
        {field.content}
      </Col>
    );
  };

  const renderMapField = (field) => (
    <Col span={24} key={field.name}>
      <div className="mt-4">{field.content}</div>
    </Col>
  );

  const renderSection = (groupKey, fields) => {
    const groupTitle = schema.groups?.[groupKey]?.title || groupKey;
    const groupConfig = schema.groups?.[groupKey];
    const shouldRenderTitle = !groupConfig?.noTitle;
    // Filter fields by type
    const shouldRenderGroupTitle = groupKey !== "guardianDetail" || isMinor;

    const normalFields = fields.filter(
      (field) =>
        !schema.properties[field.name]?.isMapField &&
        schema.properties[field.name]?.type !== "array"
    );
    const arrayFields = fields.filter(
      (field) => schema.properties[field.name]?.type === "array"
    );
    const mapFields = fields.filter(
      (field) => schema.properties[field.name]?.isMapField
    );

    return (
      <div key={groupKey} className="mb-8">
        {shouldRenderTitle && shouldRenderGroupTitle && (
          <h4 className="mb-3 text-primary ">{groupTitle}</h4>
        )}

        {/* Render normal fields */}
        {normalFields.length > 0 && (
          <Row gutter={[16, 16]} className="mb-4">
            {normalFields.map((field) =>
              renderNormalField(field, groupKey, normalFields.length)
            )}
          </Row>
        )}
        <Divider />
        {/* Render array fields */}
        {arrayFields.length > 0 && (
          <Row gutter={[16, 16]} className="mb-4">
            {arrayFields.map((field) => renderArrayField(field))}
          </Row>
        )}

        {/* Render map fields */}
        {mapFields.length > 0 && (
          <Row gutter={[16, 16]} className="mb-4">
            {mapFields.map((field) => renderMapField(field))}
          </Row>
        )}
      </div>
    );
  };

  return (
    <div>
      <h4 className="mb-3 text-primary ">{title}</h4>
      {sortedGroups.map(([groupKey, fields]) =>
        renderSection(groupKey, fields)
      )}
    </div>
  );
};

export default ObjectFieldTemplate;