import React, { useState } from "react";
import { DatePicker, message } from "antd";
import dayjs from "dayjs";
import { useAnonymous } from "../context/anonymousContext";

const CustomDatePicker = (props) => {
    const { onChange, value, options, label, formContext } = props;
    const { enforceAgeRestriction, name, futureDateShow } = options;
    const [error, setError] = useState("");
    const { status } = useAnonymous();

    // Default date for DOB
    const getDefaultOpenDate = () => {
        if (name === 'dateOfBirthAD') {
            if (formContext?.formData?.salutation === "MINOR") {
                return dayjs(); // Open to current date if salutation is MINOR
            }
            return dayjs().subtract(18, 'years'); // Otherwise, open to 18 years ago
        }
        return dayjs(); // For other date fields, use current date
    };

    const handleDateChange = (date) => {
        const selectedDate = dayjs(date?.$d).format("YYYY-MM-DD");

        if (enforceAgeRestriction && !formContext?.formData?.salutation === "MINOR") {
            const age = dayjs().diff(selectedDate, "years");
            if (age < 18) {
                setError("You must be at least 18 years old.");
                message.error("You must be at least 18 years old.");
                return;
            }
        }

        setError("");
        onChange(selectedDate);
        options.onDateChange && options.onDateChange(selectedDate);
    };

    const getDisabledDate = (current) => {
        if (!current) return false;
        if (futureDateShow) {
            return false;
        }

        if (name === 'dateOfBirthAD') {
            if (formContext?.formData?.salutation === "MINOR") {
                // Disable future dates if salutation is MINOR
                return current.isAfter(dayjs());
            }
            // For date of birth: disable dates beyond the past 18 years
            const eighteenYearsAgo = dayjs().subtract(18, 'years');
            const hundredYearsAgo = dayjs().subtract(100, 'years');
            return current.isAfter(eighteenYearsAgo) || current.isBefore(hundredYearsAgo);
        } else {
            // For other fields: disable future dates
            return current.isAfter(dayjs());
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <DatePicker
                placeholder={`Select ${label}`}
                disabled={status === 'submitted'}
                value={
                    value && dayjs(value).isValid() && dayjs(value).format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD')
                        ? dayjs(value)
                        : null
                }
                onChange={handleDateChange}
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                disabledDate={getDisabledDate}
                defaultPickerValue={getDefaultOpenDate()} // Set default opened date
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
    );
};

export default CustomDatePicker;
