// import type { ThemeConfig } from "antd";
const AntdConfig = {
  token: {
    fontFamily: "avenir",

    colorPrimary: "#0c4da2",
    // colorPrimary: "#ed1c24",
    // colorSuccess: "rgb(0, 132, 61)",
    colorError: "#ed1c24",
    // colorWarning:"rgb(252, 227, 0)"
  },
  components: {
    // Carousel: {
    //   fontFamily: "IBM Plex Sans",
    // },
    Radio: {
      colorPrimary: "#0c4da2",
      radioSize: 16,
      dotSize: 8,
      wireframe: true,
    },
    Button: {
      borderRadius: 5,
      padding: "0 20px", // This replaces paddingInline with padding
      controlHeight: 42,
      lineHeight: 1.8,
      fontSize: 16,
      colorBorder: '#ed1c24',
      colorPrimary: "#ed1c24",
      primaryColor: "#fff",
      colorPrimaryBorder: "#ed1c24",
      colorPrimaryHover: "#a31319",
      colorPrimaryActive: "#a31319",
      primaryShadow: 'none',

      defaultBg: "#fff",
      defaultBorderColor: "#ed1c24",
      defaultColor: "#ed1c24",
      defaultActiveBg: "#a31319",
      defaultActiveColor: "#ffffff",
      defaultHoverColor: "#ffffff",
      defaultHoverBg: "#a31319",
      defaultHoverBorderColor: "#a31319",
      borderColorDisabled: '#d9d9d9',

      colorLink: "#ed1c24",
      colorLinkActive: "#ed1c24",
      colorLinkHover: "#a31319"
    },
    Input: {
      borderRadius: 5,
      borderRadiusSM: 5,
      borderRadiusLG: 5,
      controlHeight: 44,
      controlHeightSM: 44,
      controlHeightLG: 56,
      fontSize: 16,
      colorBorder: "#E6E6E6",
      hoverBorderColor: "#707C8B",
      activeShadow: "0 0 0 2px rgba(5, 145, 255, 0.1)",
      fontSizeIcon: 20,
      colorIcon: '#0c4da2'
    },
    Tag: {
      borderRadiusSM: 2,
      fontSize: 16,
      colorWarning: "rgb(232, 111, 37)",
      colorWarningBorder: "#FFBB96",
      colorWarningBg: "rgb(254, 241, 225)",

      colorInfo: "rgb(44, 92, 197)",
      colorInfoBorder: "rgb(44, 92, 197)",
      colorInfoBg: "rgb(229, 242, 253)",
    },
    Table: {
      borderRadius: 0,
      borderRadiusLG: 0,
      colorBgContainer: "#ffffff",
      fontWeightStrong: 500,
      paddingContentVertical: 14,
      colorBorderSecondary: "#DFE0E2",
      colorText: "rgba(0, 0, 0, 0.65)",
      cellPaddingBlock: 10,
      cellFontSize: 14,
      headerBg: "rgb(0, 132, 61)",
      headerColor: "#FFFFFF",
      headerSortHoverBg: "#00833C",
      headerSortActiveBg: "#00833C",

      headerSplitColor: "rgb(0, 132, 61)",
      // "rowHoverBg": "rgb(82, 196, 26)",
    },
    Menu: {
      borderRadius: 0,
      radiusItem: 0,
      // dropdownWidth: 160,
      itemBorderRadius: 2,
      radiusSubMenuItem: 0,
      subMenuItemBorderRadius: 0,
      itemColor: "rgba(0, 0, 0, 0.85)",
      itemSelectedColor: "#1890FF",
      margin: 0,
      fontSize: 14,
      itemHeight: 45,

      // colorBgContainer: "#ffffff",
    },
    Cascader: {
      controlItemBgActive: "#e6f4ff",
      borderRadiusSM: 2,
      fontSizeIcon: 12,
    },
    Checkbox: {
      colorPrimaryTextHover: "#0c4da2",
    },
    Collapse: {
      headerBg: "rgba(255, 255, 255, 0.02)",
      colorText: "rgb(97, 97, 97)",
      colorTextHeading: "rgb(51, 51, 51)",
      colorBorder: "rgba(0,0,0,0)",
    },
    Slider: {
      trackBg: "rgb(236, 33, 39)",
      railBg: "#FFDEDE",
      railHoverBg: "rgb(236, 33, 39)",
      colorBgElevated: "rgb(25,135,84)",
      handleColor: "rgb(255, 255, 255)",
      handleActiveColor: "rgb(255, 255, 255)",
      trackHoverBg: "rgb(236, 33, 39)",
      dotActiveBorderColor: "rgb(255, 255, 255)",
      colorPrimaryBorderHover: "rgb(255, 255, 255)",
    },

    InputNumber: {

      borderRadius: 5,
      borderRadiusSM: 5,
      borderRadiusLG: 5,
      controlHeight: 44,
      controlHeightSM: 44,
      controlHeightLG: 56,
      fontSize: 16,
      colorBorder: "#E6E6E6",
      // hoverBg: "rgba(255, 255, 255, 0)",
      handleHoverColor: "rgba(22, 119, 255, 0)",
      activeBorderColor: "#0c4da2",
      hoverBorderColor: "#707C8B",
      colorPrimary: "rgb(217, 217, 217)",
      addonBg: "rgb(255, 255, 255)",
    },
    Select: {
      borderRadius: 5,
      colorPrimary: "rgb(51, 51, 51)",
      colorPrimaryHover: "rgba(51, 51, 51, 0.7)",
      controlOutline: "#919599",
      controlOutlineWidth: 0,
      singleItemHeightLG: 56,
      singleItemHeightSM: 44,
      singleItemHeight: 44,
      multipleItemHeight: 44,

      borderRadiusLG: 5,
      borderRadiusSM: 5,
      borderRadiusXS: 5,
      colorTextPlaceholder: "rgb(51,51,51)",
      optionSelectedColor: "#ffffff",
      colorText: "#161616",
      optionSelectedBg: "#0c4da2",
      controlHeight: 44,
      controlHeightSM: 44,
      optionSelectedFontWeight: 500,
      colorBorder: "#919599",
      hoverBorderColor: "#0c4da2"
    },
    Modal: {
      colorBgMask: "rgba(4,65,137,0.8)"
    },
    Drawer: {
      colorBgMask: "rgba(4,65,137,0.8)",
      padding: 15,
      paddingLG: 15,
      paddingXS: 15
    },
    Pagination: {
      colorPrimary: "rgb(197,22,29)",
      fontWeightStrong: 700,
      colorPrimaryHover: "rgb(197,22,29)",
      colorBgTextActive: "rgb(197,22,29)"
    },
    Dropdown: {
      borderRadius: 5
    },
    Form: {
      labelFontSize: 15,
      labelHeight: 28,
      itemMarginBottom: 0
    },
    DatePicker: {
      controlHeight: 44
    },
    Steps: {
      lineWidth: 10,
      colorPrimary: "#ED1C24",
      iconSize: 60,
      customIconSize: 60,
      //  fontSize: 20,
      iconFontSize: 20,
      lineHeight: 2
    }
  },
};

export default AntdConfig;
