import React from "react";
import { useParams } from "react-router-dom";
const BannerWidget = () => {
  const { id: paramsId, stepslug, slug } = useParams();
  return (
    <div className="container-fluid mt-4">
      <div
        className="overlay border bag-img white-overlay"
        style={{
          background:
            'url("https://www.machbank.com/online-account/assets/frontend/image/cover.png")',
        }}
      >
        <div className=" container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h1 className="text-primary"> MBL EKYC </h1>
              <h1 className="text-primary">EKYC Personal Form</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerWidget;
