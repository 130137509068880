import React, { useState, useEffect, useMemo } from "react";
import { Table, Button, Space, Modal, Input, Form } from "antd";

const TableWidgets = ({ schema, formContext }) => {
  // Initial debugging logs
  // console.log("Form Context:", formContext);
  // console.log("Schema:", schema);

  const [data, setData] = useState([]);

  useEffect(() => {
    // Check if formContext.users is defined and log its value
    if (formContext && formContext.users) {
      // console.log("formContext.users:", formContext.users);
      setData(formContext.users);
    } else {
      console.error("formContext.users is undefined or empty.");
    }
  }, [formContext]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Log data after setting it
  useEffect(() => {
    console.log("Data after initialization:", data);
  }, [data]);

  const saveData = (updatedData) => {
    setData(updatedData);
  };

  const handleAction = (action, record) => {
    const actionObj = schema.actions.find((a) => a.type === action);
    if (!actionObj) return;

    console.log(`Action: ${action}, URL: ${actionObj.url}`);

    Modal.confirm({
      title: actionObj.message,
      onOk: () => {
        if (action === "delete") {
          const updatedData = data.filter((item) => item.key !== record.key);
          saveData(updatedData);
        } else if (action === "edit") {
          setEditingRecord(record);
          setIsModalVisible(true);
        } else if (action === "add") {
          const newRow = {
            key: Date.now().toString(),
            name: "",
            age: "",
            address: "",
          };
          const updatedData = [...data, newRow];
          saveData(updatedData);
        }
      },
    });
  };

  const handleEditSave = (values) => {
    const updatedData = data.map((item) =>
      item.key === editingRecord.key ? { ...editingRecord, ...values } : item
    );
    saveData(updatedData);
    setIsModalVisible(false);
    setEditingRecord(null);
  };

  const generateColumns = useMemo(() => {
    return schema.columns.map((column) => {
      if (column.key === "actions") {
        return {
          title: column.title,
          key: column.key,
          render: (_, record) => (
            <Space>
              {schema.actions.map((action) => (
                <Button
                  key={action.type}
                  type="link"
                  danger={action.type === "delete"}
                  onClick={() => handleAction(action.type, record)}
                >
                  {action.type.charAt(0).toUpperCase() + action.type.slice(1)}
                </Button>
              ))}
            </Space>
          ),
        };
      }
      return column;
    });
  }, [schema, data]);

  if (error) {
    return <div style={{ color: "red" }}>Error: {error}</div>;
  }

  return (
    <div>
      <h2>{schema.title || "Table"}</h2>
      <Button type="primary" onClick={() => handleAction("add")}>
        Add Row
      </Button>
      <Table
        columns={generateColumns}
        dataSource={data}
        loading={loading}
        rowKey="key" // Ensure this matches the unique key in data objects
        bordered
        pagination={{ pageSize: 10 }}
      />
      {isModalVisible && (
        <Modal
          title="Edit Row"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            initialValues={editingRecord}
            onFinish={handleEditSave}
            layout="vertical"
          >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="age" label="Age" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default TableWidgets;
